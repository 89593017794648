import { template as template_4b6d426bd2e74c94a9974b94e50d1e6b } from "@ember/template-compiler";
const SidebarSectionMessage = template_4b6d426bd2e74c94a9974b94e50d1e6b(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
