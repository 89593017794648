import { template as template_88d28f8aa1ea4090b3cce59a17b1a47b } from "@ember/template-compiler";
const FKText = template_88d28f8aa1ea4090b3cce59a17b1a47b(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
