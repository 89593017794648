import { template as template_a235ea1409dd4c23966e1a7568c82b33 } from "@ember/template-compiler";
const WelcomeHeader = template_a235ea1409dd4c23966e1a7568c82b33(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
